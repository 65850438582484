import { graphql, PageProps } from 'gatsby';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ButtonLoader } from '../components/button-loader/button-loader';
import { Container } from '../components/container/container.component';
import { EventItem } from '../components/event-item/event-item';
import { Footer } from '../components/footer/footer';
import { Navbar } from '../components/navbar/navbar';
import { PageSection } from '../components/page-section/page-section';
import RichText from '../components/rich-text/rich-text';
import { SeoComponent } from '../components/seo-component/seo-component';
import { NavTabTextItem } from '../components/tabs-menu/nav-tab-text-item/nav-tab-text-item';
import { TabsNav } from '../components/tabs-menu/tabs-nav/tabs-nav';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { withCookieBar } from '../hoc/withCookieBar';
import { useGlobalTranslations } from '../hooks/use-global-translations';
import { EventModel, IEventItem } from '../models/event-model';
import { PageSectionModel } from '../models/page-section-model';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import '../styles/global.scss';
import '../styles/reset.css';
import styles from '../styles/speaking-page.module.scss';
import { pageDataResolver } from '../utils/pageDataResolver';
import { filterById } from '../utils/utils';

interface IData {
  kontentItemPage: IPage;
  allKontentItemEvent: {
    nodes: IEvent[];
  };
  presentationsSection: IPageSection;
  cookieSection: ICookieData;
}

export interface ISortedEvents {
  upcomingEvents: IEventItem[];
  pastEvents: IEventItem[];
}

type SpeakingPageProps = PageProps<IData, IPageContext>;

const SpeakingPage: FunctionComponent<SpeakingPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [progressLinePostition, setProgressLinePostition] = useState(0);
  const eventsToShow = 3;
  const [upcomingEventsListLength, setUpcomingEventsCount] = useState(
    eventsToShow
  );
  const [pastEventsListLength, setPastEventsCount] = useState(eventsToShow);
  const {
    whereAndWhen,
    upcomingEvents,
    pastEvents,
    noEvents
  } = useGlobalTranslations();
  const navTabs = [upcomingEvents, pastEvents];

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });

    const debouncedResize = debounce(handleResize, 300);

    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    handleChangePageState();
    handleResize();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.location.state,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice
  ]);

  const filteredEvents = useMemo(
    (): IEvent[] => filterById(props.data.allKontentItemEvent.nodes),
    [props.data.allKontentItemEvent.nodes]
  );

  const modeledEvents = useMemo(
    () => filteredEvents.map(ev => EventModel.create(ev)),
    [filteredEvents]
  );

  const sortedEvents = useMemo(() => {
    const today = new Date();
    return modeledEvents.reduce(
      (events: ISortedEvents | undefined, ev: IEventItem) => {
        if (!events) {
          return undefined;
        }

        const eventDate = new Date(ev.date);

        if (today > eventDate) {
          events.pastEvents.push(ev);
          return events;
        }

        events.upcomingEvents.push(ev);
        return events;
      },
      {
        upcomingEvents: [],
        pastEvents: []
      }
    );
  }, [modeledEvents]);

  const changeTabHandler = (event: React.MouseEvent<HTMLElement>): void => {
    const elem = event.target as HTMLDivElement;
    const newProgressLinePostion = elem.offsetLeft;
    const index = elem.dataset.index || 0;

    event.preventDefault();

    if (+index !== activeTabIndex) {
      setActiveTabIndex(+index);
      setProgressLinePostition(newProgressLinePostion);
    }
  };

  const presentationsSectionData = useMemo(
    () =>
      props.data.presentationsSection
        ? PageSectionModel.create(props.data.presentationsSection)
        : null,
    [props.data.presentationsSection]
  );

  const upcomingEventsList = sortedEvents?.upcomingEvents.slice(
    0,
    upcomingEventsListLength
  );

  const pastEventsList = sortedEvents?.pastEvents.slice(
    0,
    pastEventsListLength
  );

  const onUpcomingEventsButtonClick = (): void => {
    setUpcomingEventsCount(upcomingEventsListLength + eventsToShow);
  };

  const onPastEventsButtonClick = (): void => {
    setPastEventsCount(pastEventsListLength + eventsToShow);
  };

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <RichText {...props.data.kontentItemPage.elements.body} />
      <Container>
        <div className={styles.eventsWrapper} id="read-more">
          <h3 className={styles.header}>{whereAndWhen}</h3>
          <TabsNav
            progressLinePostition={progressLinePostition}
            className={styles.tabsNav}
          >
            {navTabs.map((navTab, i) => (
              <NavTabTextItem
                isActive={activeTabIndex === i}
                key={i}
                index={i}
                text={navTab}
                changeTab={changeTabHandler}
              />
            ))}
          </TabsNav>
          <div className={styles.data}>
            {upcomingEventsList &&
            activeTabIndex === 0 &&
            sortedEvents?.upcomingEvents.length ? (
              <div className={styles.eventsList}>
                {sortedEvents?.upcomingEvents.map((ev, i) => (
                  <EventItem
                    displayAuthor
                    key={i}
                    language={props.pageContext.pageLanguage}
                    {...ev}
                  />
                ))}
                {sortedEvents &&
                  upcomingEventsListLength <
                    sortedEvents?.upcomingEvents.length && (
                    <div className={styles.loader}>
                      <ButtonLoader
                        buttonText="Load more"
                        onClick={onUpcomingEventsButtonClick}
                      />
                    </div>
                  )}
              </div>
            ) : (
              activeTabIndex === 0 && noEvents
            )}
            {pastEventsList && activeTabIndex === 1 && (
              <div className={styles.eventsList}>
                {pastEventsList.map((ev, i) => (
                  <EventItem
                    displayAuthor
                    pastEvent
                    key={i}
                    language={props.pageContext.pageLanguage}
                    {...ev}
                  />
                ))}
                {sortedEvents &&
                  pastEventsListLength < sortedEvents?.pastEvents.length && (
                    <div className={styles.loader}>
                      <ButtonLoader
                        buttonText="Load more"
                        onClick={onPastEventsButtonClick}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </Container>
      {presentationsSectionData && (
        <PageSection {...presentationsSectionData} />
      )}
      {!isEmpty(pageData.footerData) && <Footer {...pageData.footerData} />}
    </>
  );
};

export const Head = (props: SpeakingPageProps) => {
  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  return <SeoComponent {...pageData.seoData} />;
};

export default withAppStore(
  withCookieBar(SpeakingPage as FunctionComponent<{}>)
);

export const query = graphql`
  query($pageId: String, $pageLanguage: String) {
    kontentItemPage(
      system: { id: { eq: $pageId }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentPageComponent
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    allKontentItemEvent(
      filter: { system: { language: { eq: $pageLanguage } } }
      sort: { fields: elements___date___value, order: DESC }
    ) {
      nodes {
        ...FragmentEvent
      }
    }
    presentationsSection: kontentItemPageSection(
      system: { codename: { eq: "presentations_section" } }
    ) {
      ...FragmentPageSection
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
